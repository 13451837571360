import React from 'react';
import Lottie from 'react-lottie';
import loadingCode from '../../images/loadingCode.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingCode,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const IconLoader = () => (
  <div>
    <Lottie options={defaultOptions} height={450} width={450} />
  </div>
);

export default IconLoader;
