import React from 'react';
import logo from '../../images/logo.png';

const IconLogo = () => (
  <div>
    <img src={logo} alt="logo" />
  </div>
);

export default IconLogo;
